.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.background-video {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

header {
  height: 25vh;
  position: relative;
  overflow: hidden;
  display: flex;          /* Enables flexbox for the container */
  align-items: center;    /* Vertically centers the child elements */
  justify-content: flex-start; /* Aligns the child elements to the left */
}


header h1.mobile {
  display: none;
}

header h1.desktop {
  font-size: 3.5rem;
  text-align: left;
  padding-left: 30px;
  padding-top: 0;         /* Ensures no top padding */
  margin-top: 0;          /* Ensures no top margin */
  z-index: 1;
  color: #FFFFFF;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 
}

body {
  background-color: #1A2634;
  /* Deep blue background */
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.App-header {
  background-color: #2C3E50;
  /* Slightly lighter blue */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #E1E5EA;
  /* Light gray for the text */
}

.App-link {
  color: #3498DB;
  /* Bright blue for links */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.image-container {
  height: 55vh;
  position: relative;
  display: inline-block;
  padding-top: 50px;
  text-align: center;
  overflow: hidden;
}

.image-container img {
  max-height: 55vh; /* To prevent spilling over container */
  width: auto;
  height: auto;
  object-fit: contain;
  display: block;
  margin: auto;
}

.choice-symbol {
  position: absolute;
  top: calc(50% - 70px);  /* This positions the box just above the centered caption. Adjust if necessary. */
  left: 50%;             
  color: #ffffff;
  max-width: calc(100% - 10px); 
  word-wrap: break-word; 
  overflow-wrap: break-word;
  transform: translate(-50%, -50%); /* Center the box horizontally and shift up a bit. */
  text-align: center;
  z-index: 10;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;  /* To prevent wrapping and ensure it remains on one line. */
}


.choice-symbol:before {
  content: none;
  display: inline-block;
  text-align: center;
  font-size: 1em; 
  font-weight: bold;
  min-height: 1em;
  color: #ffffff;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  margin-bottom: 5px;  /* Space between the correct/incorrect text and the caption */
}

.choice-correct:before {
  content: "Correct ✓";
  color: green;  /* You can adjust the color if needed */
}

.choice-wrong:before {
  content: "Incorrect ✗";
  color: red;  /* You can adjust the color if needed */
}



.caption{
  position: absolute;
  top: 50%;         /* Centers the top edge to the middle of the image */
  left: 50%;        /* Centers the left edge to the middle of the image */
  color: #ffffff;
  min-height: 30px;
  max-width: calc(100% - 10px);
  word-wrap: break-word; 
  overflow-wrap: break-word;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 10;
}

.ai-caption, .image-link {
  color: #ffffff;
  padding: 5px 10px; 
  background-color: rgba(0, 0, 0, 0.7); 
  border-radius: 5px;
}

.image-link:hover {
  text-decoration: none; /* To remove the underline when hovering */
}

.image-link {
  text-decoration: underline;
}

.caption .title {
  font-size: 1em;
  color: #E1E5EA;
}

.caption .artist,
.caption .year {
  font-style: italic;
  color: #BDC3C7;
  /* Slightly dimmer gray for secondary text */
}

.buttons {
  position: fixed;         
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  gap: 10px;
}

.buttons button {
  display: inline-block;
  background-color: #61dafb;
  /* Bright blue for buttons */
  color: #333;
  /* Light gray for button text */
  padding: 10px 20px;
  /* Slight padding for buttons */
  border: none;
  border-radius: 5px;
  /* Rounded corners for buttons */
  transition: background-color 0.3s;
  /* Smooth transition effect */
  margin-right: 15px;
}

.buttons button:hover {
  background-color: #154e75;
  /* Slightly darker blue for button hover */
}

.buttons button:focus {
  outline: none; /* Removes the default browser outline */
  background-color: #61dafb; /* Resets to default background */
}

@media (hover: hover) {
  .buttons button:not(:hover) {
      background-color: #61dafb;
  }
}


body,
html {
  margin: 0;
  padding: 0;
}

.footer {
  display: flex; /* Makes the footer a flex container */
  align-items: center; /* Vertically centers the items */
  justify-content: space-between; /* Distributes the space between the items */
  background-color: transparent;
  position: fixed;
  bottom: 10px;
  width: 100%; /* Ensures the footer spans the full width */
  z-index: 1000;
  box-sizing: border-box;
}

.footer.row {
  width: 100vw;
}

.footer .product-hunt-badge {
  text-align: right;
}

.footer .progress-counter {
  order: -1;
  text-align: left;
}

.footer .contact-link {
  flex: 1; /* Takes up equal space */
  text-align: right; /* Aligns text to the left */
}


.footer p {
  display: inline-block;
  padding: 10px 20px;
  border: 3px solid #61dafb;
  border-radius: 25px;
  font-size: 1.0rem;
  text-transform: uppercase;
  color: #61dafb;
  margin: 0;
  background-color: rgba(40, 44, 52, 0.8);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.product-hunt-badge img {
  width: 200px;
  height: 43.1px;
}

.choice-symbol {
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  min-height: 1em;
}

.choice-correct {
  color: green;
}

.choice-wrong {
  color: red;
}

.layout {
  position: relative;
}

.contact-link {
  padding: 5px 15px;       /* Add some padding for better appearance */
  border-radius: 5px;      /* Rounded corners */
  color: #61dafb;          /* Color of the text */
  text-decoration: none;   /* Remove underline */
}

.contact-link:hover {
  text-decoration: underline; /* Underline on hover */
  color: #61dafb;  /* You can adjust the hover color if needed */
}

@media (min-width: 768px) {
  .footer {
      padding: 20px 40px;  /* Adjust these values as per your design preference */
      border-top: 2px solid #2C3E50;
  }
  .footer .product-hunt-badge {
    margin-left: 20px; /* Add space to the right of the Product Hunt badge */
  }

  .footer .contact-link {
      margin-right: 20px; /* Add space to the left of the Contact link */
  }
}

/* Default styles for mobile devices */
@media (max-width: 767px) {
  html, body {
    overflow-x: hidden;
    width: 100%;
}

header {
    height: 5vh; /* Fixed height for the header */
    overflow: hidden; /* This will ensure content that exceeds the height is not visible */
    box-sizing: border-box;
}
  
  header h1.mobile {
    font-size: 3.75vh;      
    display: block;
    text-align: left;
    padding-left: 5px;
    margin: 0; 
    z-index: 1;
    color: #FFFFFF;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2); 
    font-family: 'Kanit';
  }
  
  header h1.desktop {
    display: none;
  }

  :focus {
    outline: none;
  }

  .image-container {
    display: flex;
    align-items: center; /* vertical centering */
    justify-content: center; /* horizontal centering */
    padding-top: 0;
    overflow: hidden; /* This ensures the image won't spill out */
  }

  .image-container img {
    max-height: 55vh; /* To prevent spilling over container */
    height: auto; /* It will adjust height based on aspect ratio */
    object-fit: cover; /* This will make sure image covers the container while maintaining aspect ratio */
  }
  

.App-logo {
  height: 20vmin;
}

.footer {
  padding: 1px 20px;
}

.footer p, .footer a {
  font-size: 1vh;      
  padding: 1vh 2vh;   
  border-radius: 3vh;  
}

.product-hunt-badge img {
  width: 13.89vh;     
  height: 3vh;   
}

}

.no-underline {
  text-decoration: none !important;
}

/* Mobile-first CSS - hide the additional text by default */
.responsive-text {
  display: none;
}

/* Display the additional text on larger screens */
@media screen and (min-width: 768px) {  /* adjust the breakpoint (768px) as needed */
  .responsive-text {
      display: inline;
  }
}

/* For larger screens */
@media screen and (min-width: 768px) { 
  body {
    overflow-y: hidden;  /* Blocks vertical scroll */
  }
  .row {
    flex-wrap: nowrap; /* Disable wrapping, so columns stay side by side */
  }

  .image-container {
    width: 50%; /* Half the screen width */
    height: 100vh;
    overflow: hidden;
  }

  .image-container img {
    height: 100vh;
    width: auto;
    max-width: none; /* Allow the image to grow beyond its container */
  }

  .buttons {
    bottom: 40%;
    left: 65%;
  }

  .buttons button {
    padding: 15px 30px;
    margin-left: 20px; /* Adjust the value as needed */
  }
  
  header h1.desktop {
    text-align: center; /* Center the header for desktop */
    margin-bottom: 2rem; /* Add some space below the header */
  }

}


.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
