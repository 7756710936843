/* Overall Form Container */
.contact-form {
    color: #ffffff;
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    background-color: rgba(0,0,0,0.1);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Input fields (text, textarea) */
.input-field {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

/* Button enhancement */
.submit-button {
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
}

.submit-button:hover {
    background-color: #45a049;
}

.contact-form label {
    color: #ffffff; /* Ensures labels also have white text color */
}

.contact-form h3 {
    color: #ffffff; /* Ensures the h3 heading has white text color */
}