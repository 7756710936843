.scorepage-background {
    position: relative; 
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
  }

  .scorepage-background-high {
    background-image: url('./images/high-score-background.png');  /* Image for score > 50% */
  }

  .scorepage-background-low {
    background-image: url('./images/low-score-background.png');   /* Image for score <= 50% */
  }
  
  .scorepage-background::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7); /* This applies a 50% opacity black overlay */
    z-index: 1; /* Ensures the overlay is above the background image */
  }
  
  .scorepage-content {
    position: relative; /* This makes sure the content is above the overlay */
    z-index: 2;
    display: flex; /* Enables flexbox */
    flex-direction: column; /* Stacks children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
    height: 100vh; /* Take up the full viewport height */
    text-align: center; /* Center the text */
  }
  
  .score-display {
    font-family: 'Roboto', sans-serif;
    font-size: 2.5rem;
    color: #E1E5EA; /* Light gray */
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    background-color: rgba(225, 229, 234, 0.1);  
    border-radius: 8px; 
  }

  .score-feedback {
    font-size: 1.25rem;  /* Adjust this value to your liking */
    margin-top: 0.625rem; /* Provides spacing between the score and the feedback */
    color: #E1E5EA;     /* Optional: A slightly lighter color for subtle emphasis */
  }
  
  
  .play-again-button {
    font-size: 1.5rem;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
    background-color: #E74C3C; /* Red */
    border: none;
  }

  .a2a_kit {
    margin-bottom: 1.5rem;  /* Adjust this value as per your requirement */
  }

  
  .feedback-text {
    font-size: 1rem;
    color: #d58543; /* Light gray */
    text-align: center;
  }
  